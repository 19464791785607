import clsx from 'clsx';

export type ButtonClassProps = {
  border?: boolean;
  borderType?: 'squared' | 'round';
  compact?: boolean;
  variant?: 'primary' | 'outlined' | 'plain';
  disabled?: boolean;
  contentAlign?: 'center' | 'start' | 'end';
  color?: 'error' | 'warning' | 'success' | 'white' | 'transparent';
  padding?: boolean;
  isSelected?: boolean;
  fullWidth?: boolean;
  forceInline?: boolean;
  className?: string;
};

export function generateButtonClass({
  border,
  borderType = 'round',
  compact = false,
  variant = 'outlined',
  disabled = false,
  color,
  padding = true,
  isSelected = false,
  fullWidth = false,
  forceInline = false,
  className,
  contentAlign = 'center',
}: ButtonClassProps) {
  let buttonColorClass = null;
  let buttonColorHoverClass = null;
  let textHoverColorClass = null;
  let borderColorClass = null;
  let selectedClass = null;
  let textClass = null;
  switch (color) {
    case 'error':
      buttonColorClass = variant === 'primary' && 'bg-red-700';
      buttonColorHoverClass = 'hover:bg-red-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-red-300' : 'hover:text-white';
      borderColorClass = 'border-red-600';
      selectedClass = 'bg-red-500 hover:bg-red-500';
      textClass = 'text-white';
      break;
    case 'success':
      buttonColorClass = variant === 'primary' && 'bg-green-700';
      buttonColorHoverClass = 'hover:bg-green-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-green-300' : 'hover:text-white';
      borderColorClass = 'border-green-600';
      selectedClass = 'bg-green-500 hover:bg-green-500';
      textClass = 'text-white';
      break;
    case 'warning':
      buttonColorClass = variant === 'primary' && 'bg-orange-700';
      buttonColorHoverClass = 'hover:bg-orange-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-orange-300' : 'hover:text-white';
      borderColorClass = 'border-orange-600';
      selectedClass = 'bg-orange-500 hover:bg-orange-500';
      textClass = 'text-white';
      break;
    case 'white':
      buttonColorClass = variant === 'primary' && 'bg-gray-50 text-slate-800';
      buttonColorHoverClass =
        variant === 'primary' ? 'hover:bg-gray-100' : 'hover:bg-gray-600';
      textHoverColorClass = variant === 'plain' ? 'hover:text-white' : null;
      borderColorClass = 'border-gray-300 hover:border-gray-100';
      selectedClass = 'bg-gray-50 hover:bg-gray-50 text-slate-800 border-white';
      textClass =
        variant === 'primary'
          ? 'text-slate-900 hover:text-white'
          : 'text-white';
      break;
    case 'transparent':
      buttonColorClass = 'bg-transparent';
      borderColorClass = 'border-white';
      textClass = 'text-white';
      break;
    default:
      buttonColorClass = variant === 'primary' && 'bg-purple-700';
      buttonColorHoverClass = 'hover:bg-purple-500';
      textHoverColorClass =
        variant === 'plain' ? 'hover:text-purple-300' : 'hover:text-white';
      borderColorClass = 'border-purple-600';
      selectedClass = 'bg-purple-500 hover:bg-purple-500';
      textClass = 'text-white';
  }

  const shouldHideBorder = variant === 'plain' || border === false;

  return {
    containerClass: clsx(
      borderType === 'squared' ? 'rounded-none' : 'rounded-lg',
      `rounded-lg shadow-lg transition duration-200`,
      padding && 'px-4 py-2',
      !shouldHideBorder && 'border-2',
      variant === 'plain' && 'bg-transparent',
      isSelected && selectedClass,
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      compact ? 'h-6' : 'h-10',
      'inline-flex items-center',
      contentAlign === 'start' && 'justify-start',
      contentAlign === 'end' && 'justify-end',
      contentAlign === 'center' && 'justify-center',
      fullWidth && 'w-full',
      forceInline && 'whitespace-nowrap break-keep',
      textClass,
      buttonColorClass,
      buttonColorHoverClass,
      textHoverColorClass,
      borderColorClass,
      className,
    ),
    innerContainerClass: clsx(
      'gap-2 inline-flex items-center justify-center',
      disabled && 'opacity-50',
    ),
  };
}
